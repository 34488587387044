import Vue from 'vue'
import Vuex from 'vuex'
import FormularioRegistro from './modules/formularioRegistro/formularioRegistro';
import Auth from './modules/Auth/Auth';
import Encuenta from './modules/Encuenta/Encuenta';
import catalogs from './modules/catalogs/catalogs';
import Satisfaccion from './modules/Satisfaccion/Satisfaccion';



Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    width: 1024,
    height: 0
  },
  mutations: {
    setWidth(state, payload) {
      state.width = payload
    },
    setHeight(state, payload) {
      state.height = payload
    }
  },
  actions: {
    setResize({ commit }, data) {
      commit("setWidth", data.width)
      commit("setHeight", data.height)
    },
  },
  modules: {
    Auth,
    FormularioRegistro,
    Encuenta,
    catalogs,
    Satisfaccion
  }
})
