import axios from 'axios'
import Auth from '../Auth/Auth'

export default {
  namespaced: true,
  state: {
    preguntas: [],
    initUser: {},
    saveSatisfaccion: {}
  },
  mutations: {
    setPreguntasOpciones(state, payload) {
      state.preguntas = payload
    },
    setInitUser(state, payload) {
      state.initUser = payload
    },
    setSaveSatisfaccion(state, payload) {
      state.saveSatisfaccion = payload
    },
  },
  actions: {
    async ActionGetPreguntas({ commit }, data) {

      try {
        const { encuenta_id, sub_competencia_id } = data;
        const api_server = await axios.get(`/satisfaccion/obtener-preguntas/${encuenta_id}/${sub_competencia_id}`, Auth.state.header);
        commit('setPreguntasOpciones', api_server.data.data);
      } catch (error) {
        console.log("error", error.request.status);
        if (error.request.status == 401) {
          window.location.href = '/'
        }
      }

    },
    async actionInitUsuario({ commit }, data) {

      try {

        const api_server = await axios.post(`/satisfaccion/init-satisfaccion`, data, Auth.state.header);
        if (api_server.status == 200) {
          commit('setInitUser', api_server.data);
          return true
        } else {
          return false
        }
      } catch (error) {
        console.log("error", error.request.status);
        if (error.request.status == 401) {
          window.location.href = '/'
        }
      }


    },
    async actionSavePreguntasSatifaccion({ commit }, data) {
      const api_server = await axios.post(`/satisfaccion/guardar-respuestas-satisfaccion`, data, Auth.state.header);
      commit('setSaveSatisfaccion', api_server.data);
  },
  },
  getters: {}
}